import React, { ReactNode, useContext, useMemo } from "react";

import ChatIcon from "@mui/icons-material/Chat";
import DescriptionIcon from "@mui/icons-material/Description";
import GradingIcon from "@mui/icons-material/Grading";
import HubIcon from "@mui/icons-material/Hub";
import PsychologyIcon from "@mui/icons-material/Psychology";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import ThreePIcon from "@mui/icons-material/ThreeP";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import useFsCol from "../../fs/useFsCol";
import { AgentType, MaiAgentId } from "../../models/Models";
import {
  AGENT_ID_PARAM_KEY,
  AGENT_TYPE_PARAM_KEY,
} from "../../pages/chat/ChatPageV2";
import { whereReadAccessible } from "../../utils/AclUtils";
import { fixHref } from "../utils/Utils";

interface NavItem {
  readonly name: string;
  readonly icon: ReactNode;
  readonly beta?: boolean;
  readonly link: string;
}

interface NavSection {
  readonly name: string;
  readonly html?: ReactNode;
  readonly items: NavItem[];
}

function NavBarItems() {
  const location = useLocation();
  const { selectedOrg, uid } = useContext(OrgContext);

  const agentsCol = useMemo(
    () => organization(selectedOrg).agents(),
    [selectedOrg]
  );

  const [agents, _loading, _error] = useFsCol(agentsCol, {
    where: whereReadAccessible(uid),
    orderBy: "created_at",
    orderDir: "desc",
  });

  const sections: NavSection[] = React.useMemo(
    () => [
      {
        name: "",
        items: [
          {
            name: "Knowledge Hubs",
            link: "/hubs",
            beta: true,
            icon: <HubIcon />,
          },
          {
            name: "Custom Agents",
            link: "/agents",
            beta: true,
            icon: <PsychologyIcon />,
          },
        ],
      },
      {
        name: "Document Analyzer",
        items: [
          {
            name: "Documents",
            link: "/documents",
            icon: <DescriptionIcon />,
          },
          {
            name: "Analyzers",
            link: "/analyzers",
            icon: <GradingIcon />,
          },
        ],
      },
      {
        name: "mAI Agents",
        items: [
          {
            name: "Generalist",
            link: `/chat?${AGENT_TYPE_PARAM_KEY}=${AgentType.MAI}&${AGENT_ID_PARAM_KEY}=${MaiAgentId.DEFAULT}`,
            icon: <ChatIcon />,
          },
          {
            name: "Marketing",
            link: `/chat?${AGENT_TYPE_PARAM_KEY}=${AgentType.MAI}&${AGENT_ID_PARAM_KEY}=${MaiAgentId.MARKETING}`,
            icon: <ThreePIcon />,
          },
          {
            name: "Proposal",
            link: `/chat?${AGENT_TYPE_PARAM_KEY}=${AgentType.MAI}&${AGENT_ID_PARAM_KEY}=${MaiAgentId.PROPOSAL}`,
            icon: <ChatIcon />,
          },
        ],
      },
      {
        name: "Custom Agents",
        items: [
          ...agents.map((agent) => ({
            name: agent.name,
            link: `/chat?${AGENT_TYPE_PARAM_KEY}=${AgentType.CUSTOM}&${AGENT_ID_PARAM_KEY}=${agent.id}`,
            icon: <ChatIcon />,
          })),
        ],
      },
    ],
    [agents]
  );

  return (
    <>
      {sections.map((section) => {
        return (
          <Box key={section.name}>
            <Divider sx={{ mb: section.name ? 2 : undefined }} />
            {section.name && (
              <Typography
                sx={{
                  fontWeight: 600,
                  ml: 2,
                  fontSize: "10pt",
                  textAlign: "left",
                }}
              >
                {section.name}
              </Typography>
            )}
            {section.html}
            <List id={"nav-bar-items"}>
              {section.items.map((navItem) => {
                const isActiveLink = location.pathname.startsWith(navItem.link);
                return (
                  <ListItem key={navItem.name} disablePadding>
                    <Link
                      to={fixHref(navItem.link)}
                      style={{ display: "block", width: "100%" }}
                    >
                      <ListItemButton>
                        <ListItemIcon
                          sx={{
                            color: isActiveLink ? "primary.main" : undefined,
                          }}
                        >
                          {navItem.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={navItem.name}
                          primaryTypographyProps={{
                            className: navItem.beta ? "beta-label" : undefined,
                            color: isActiveLink
                              ? "primary.main"
                              : "text.primary",
                            fontWeight: isActiveLink ? 600 : 400,
                          }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        );
      })}
    </>
  );
}

export default NavBarItems;
