import React, { useContext, useMemo, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";

import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import useFsCol from "../../fs/useFsCol";
import { Agent } from "../../models/Models";
import { whereReadAccessible } from "../../utils/AclUtils";
import ErrorDisplay from "../utils/ErrorDisplay";

interface CreateAgentProps {
  isOpen: boolean;
  onClose: () => void;
}

function CreateAgentDialog({ isOpen, onClose }: CreateAgentProps) {
  const [name, setName] = useState<string>("");
  const [isLegal, setIsLegal] = useState<boolean>(false);

  const [description, setDescription] = useState<string>("");

  const [selectedHubIds, setSelectedHubIds] = useState<string[]>([]);
  const [working, setWorking] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { selectedOrg, uid } = useContext(OrgContext);

  const hubsColRef = useMemo(
    () => organization(selectedOrg).hubs(),
    [selectedOrg]
  );

  const [hubs, hubsLoading, _error] = useFsCol(hubsColRef, {
    where: whereReadAccessible(uid),
    orderBy: "created_at",
    orderDir: "desc",
  });

  function handleClose() {
    setName("");
    setDescription("");
    setSelectedHubIds([]);
    setError(null);
    onClose();
  }

  async function handleCreateNewType() {
    if (working) {
      return;
    }

    setWorking(true);
    setError(null);

    try {
      const agent: Agent = {
        id: "",
        name: name.trim(),
        description: description.trim(),
        owner: uid,
        readers: [uid],
        writers: [uid],
        allowOrgRead: false,
        allowOrgWrite: false,
        created_at: Timestamp.now(),
        is_legal: isLegal,
        hub_ids: selectedHubIds,
      };
      const result = await organization(selectedOrg).agents().create(agent);
      handleClose();
    } catch (error) {
      setError(error as Error);
    } finally {
      setWorking(false);
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>New Custom Agent</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter a name and description for your new Custom Agent!
        </DialogContentText>
        <ErrorDisplay error={error} />
        <Box
          component="form"
          noValidate
          onSubmit={async (event) => {
            event.preventDefault();
            await handleCreateNewType();
          }}
          autoComplete="off"
        >
          <Stack spacing={2} sx={{ my: 2 }}>
            <TextField
              autoFocus
              required
              label="Name"
              name={"name"}
              data-test-id="analyzer-name"
              disabled={working}
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              required
              label="Description"
              name="description"
              data-test-id="analyzer-description"
              disabled={working}
              type="text"
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLegal}
                  onChange={(e) => setIsLegal(e.target.checked)}
                  name="isLegal"
                />
              }
              label="Is Legal?"
            />
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">
                Connect Knowledge Hubs
              </Typography>
              {hubs.length === 0 && hubsLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                  <CircularProgress />
                </Box>
              ) : hubs.length === 0 ? (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                  sx={{ p: 2 }}
                >
                  {error ? error.message : "No hubs to select from"}
                </Typography>
              ) : (
                <FormGroup>
                  {hubs.map((hub) => (
                    <FormControlLabel
                      key={hub.id}
                      control={
                        <Checkbox
                          checked={selectedHubIds.includes(hub.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedHubIds([...selectedHubIds, hub.id]);
                            } else {
                              setSelectedHubIds(
                                selectedHubIds.filter((id) => id !== hub.id)
                              );
                            }
                          }}
                        />
                      }
                      label={hub.name}
                    />
                  ))}
                </FormGroup>
              )}
            </Box>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={working}>
          Cancel
        </Button>
        <Button
          data-test-id="agent-create-button"
          onClick={handleCreateNewType}
          disabled={working}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateAgentDialog;
