import React, { useEffect, useState } from "react";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import ErrorIcon from "@mui/icons-material/Error";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { ThreeDots } from "react-loader-spinner";

import { HubFile, HubFileStateEnum, HubFileTypeEnum } from "./HubClient";
import KnowledgeHubClient from "./HubClient";

const client = new KnowledgeHubClient();

interface HubFileListProps {
  orgId: string;
  hubId: string;
}

const HubFileList: React.FC<HubFileListProps> = ({ orgId, hubId }) => {
  const [files, setFiles] = useState<HubFile[]>([]);
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [filesError, setFilesError] = useState<Error | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<HubFile | null>(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // todo: need to leverage the pagination. right now we simply wont get more than 50 files.
  const numFilesPerPage = 50;

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        setFetchingFiles(true);
        const response = await client.listFiles(orgId, hubId, numFilesPerPage);
        setFiles(response.files);
      } catch (error) {
        console.error("Error fetching files:", error);
        setFilesError(error as Error);
      } finally {
        setFetchingFiles(false);
      }
    };

    fetchFiles();
  }, [orgId, hubId, refreshTrigger]);

  const handleDeleteFile = (file: HubFile) => {
    setFileToDelete(file);
    setDeleteModalOpen(true);
  };

  const confirmDeleteFile = async () => {
    if (!fileToDelete) return;

    try {
      await client.deleteFiles(orgId, hubId, [fileToDelete.file_id]);
      setFiles(files.filter((f) => f.file_id !== fileToDelete.file_id));
      setRefreshTrigger(refreshTrigger + 1);
    } catch (error) {
      console.error("Error deleting file:", error);
    } finally {
      setDeleteModalOpen(false);
      setFileToDelete(null);
    }
  };

  const fileTypeMap: { [key in HubFileTypeEnum]: any } = {
    [HubFileTypeEnum.PDF]: <PictureAsPdfIcon />,
    [HubFileTypeEnum.DOCX]: <DescriptionIcon />,
    [HubFileTypeEnum.GOOGLE_DOC]: <DescriptionIcon />,
    [HubFileTypeEnum.GOOGLE_SLIDE]: <DescriptionIcon />,
    [HubFileTypeEnum.HTML]: <DescriptionIcon />,
    [HubFileTypeEnum.MARKDOWN]: <DescriptionIcon />,
    [HubFileTypeEnum.PPTX]: <DescriptionIcon />,
    [HubFileTypeEnum.TXT]: <DescriptionIcon />,
  };

  const getFileDisplayName = (file: HubFile) => {
    let name = file.display_name;
    if (name.endsWith(".pdf") || name.endsWith(".docx")) {
      name = name.substring(0, name.lastIndexOf("."));
    }
    return name;
  };

  return (
    <>
      {fetchingFiles && (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#4fa94d"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      )}

      {filesError && (
        <Typography variant="body1" color="error" sx={{ mt: 2 }}>
          Error loading files: {filesError.message}
        </Typography>
      )}

      <List sx={{ mt: 0, width: "100%" }}>
        {files.map((file, index) => (
          <Box key={file.file_id}>
            <ListItem sx={{ width: "100%", paddingLeft: 0 }}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ width: "100%" }}
              >
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  {file.status.state === HubFileStateEnum.ERROR && (
                    <ErrorIcon color="error" />
                  )}
                  {file.status.state === HubFileStateEnum.ACTIVE && (
                    <CheckCircleOutlineIcon color="success" />
                  )}
                </ListItemIcon>
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  {fileTypeMap[file.file_type]}
                </ListItemIcon>
                <ListItemText
                  primary={getFileDisplayName(file)}
                  sx={{
                    width: "0",
                    flexGrow: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                />
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteFile(file)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </ListItem>
            {index < files.length - 1 && <Divider />}
          </Box>
        ))}
      </List>
      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this file from the Knowledge Hub?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
          <Button onClick={confirmDeleteFile} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HubFileList;
