import { sendApiRequest } from "../utils/FetchUtils";

export enum HubSourceTypeEnum {
  GCS = "GCS",
  GOOGLE_DRIVE = "GOOGLE_DRIVE",
  SHARE_POINT = "SHARE_POINT",
}

export enum HubFileTypeEnum {
  PDF = "PDF",
  GOOGLE_DOC = "GOOGLE_DOC",
  GOOGLE_SLIDE = "GOOGLE_SLIDE",
  HTML = "HTML",
  MARKDOWN = "MARKDOWN",
  PPTX = "PPTX",
  DOCX = "DOCX",
  TXT = "TXT",
}

export enum HubFileStateEnum {
  UNSPECIFIED = "STATE_UNSPECIFIED",
  ACTIVE = "ACTIVE",
  ERROR = "ERROR",
}

interface GoogleCloudStorageSource {
  uri: string;
}

interface HubFileStatus {
  state: HubFileStateEnum;
  error_status?: string | null;
}

export interface HubFile {
  file_id: string;
  display_name: string;
  description: string;
  source: GoogleCloudStorageSource;
  source_type: HubSourceTypeEnum;
  file_type: HubFileTypeEnum;
  created_at: Date;
  updated_at: Date;
  status: HubFileStatus;
}

interface ListFilesResponse {
  hub_id: string;
  files: HubFile[];
  next_page_token: string | null;
}

export interface Citation {
  insert_index: number;
  title: string;
  excerpt: string;
  uri: string;
}

interface TestQuestionResponse {
  answer: string;
  citations: Citation[];
}

export default class KnowledgeHubClient {
  async createHub(orgId: string, hubId: string): Promise<void> {
    const response = await sendApiRequest("/knowledge-hubs/create", {
      org_id: orgId,
      hub_id: hubId,
    });
    if (!response.ok) {
      // Handle error appropriately, e.g., throw an error
      throw new Error(`Failed to create hub: ${response.status}`);
    }
  }

  async ingestFiles(
    orgId: string,
    hubId: string,
    paths: string[]
  ): Promise<void> {
    const response = await sendApiRequest("/knowledge-hubs/ingest-files", {
      org_id: orgId,
      hub_id: hubId,
      paths,
    });
    if (!response.ok) {
      throw new Error(`Failed to ingest files: ${response.status}`);
    }
  }

  async listFiles(
    orgId: string,
    hubId: string,
    pageSize: number,
    pageToken?: string
  ): Promise<ListFilesResponse> {
    const response = await sendApiRequest("/knowledge-hubs/list-files", {
      org_id: orgId,
      hub_id: hubId,
      page_size: pageSize,
      page_token: pageToken,
    });
    if (!response.ok) {
      throw new Error(`Failed to list files: ${response.status}`);
    }
    return response.json();
  }

  async deleteFiles(
    orgId: string,
    hubId: string,
    fileIds: string[]
  ): Promise<void> {
    const response = await sendApiRequest("/knowledge-hubs/delete-files", {
      org_id: orgId,
      hub_id: hubId,
      file_ids: fileIds,
    });
    if (!response.ok) {
      throw new Error(`Failed to delete files: ${response.status}`);
    }
  }

  async deleteHub(orgId: string, hubId: string): Promise<void> {
    const response = await sendApiRequest("/knowledge-hubs/delete", {
      org_id: orgId,
      hub_id: hubId,
    });
    if (!response.ok) {
      throw new Error(`Failed to delete hub: ${response.status}`);
    }
  }

  async sentTestQuestion(
    orgId: string,
    hubId: string,
    question: string
  ): Promise<TestQuestionResponse> {
    const response = await sendApiRequest("/knowledge-hubs/test-question", {
      org_id: orgId,
      hub_id: hubId,
      question: question,
    });
    if (!response.ok) {
      throw new Error(
        `Failed to send test knowledge hub question: ${response.status}`
      );
    }
    return response.json();
  }
}
