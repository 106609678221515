import React, { useContext, useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import useFsCol from "../../fs/useFsCol";
import useFsDoc from "../../fs/useFsDoc";
import { KnowledgeHub } from "../../models/Models";
import { whereReadAccessible } from "../../utils/AclUtils";
import ErrorDisplay from "../utils/ErrorDisplay";

interface ViewAgentConfigProps {
  open: boolean;
  onClose: () => void;
  agentId: string;
}

function ViewAgentConfig({ open, onClose, agentId }: ViewAgentConfigProps) {
  const [error, setError] = useState<Error | null>(null);
  const { selectedOrg, uid } = useContext(OrgContext);

  const [agent, agentLoading, agentError] = useFsDoc(
    organization(selectedOrg).agent(agentId)
  );

  // What if users permission to a hub gets taken away, but they still have access to a custom
  // agent using that hub? we probably should not allow this to happen but as of now its possible
  // if that happened then this would fail to display that hub because the user here would not have
  // access to read it.

  // Also there is certainly a better way to do this then always reading the entire
  // collection of hubs (i.e. we only need to read hubs by ID for each in agent.hub_ids)
  const hubsColRef = useMemo(
    () => organization(selectedOrg).hubs(),
    [selectedOrg]
  );

  const [hubs, hubsLoading, hubsError] = useFsCol(hubsColRef, {
    where: whereReadAccessible(uid),
    orderBy: "created_at",
    orderDir: "desc",
  });

  if (agentError) {
    setError(agentError);
  }

  const connectedHubs = useMemo(() => {
    if (agent && hubs) {
      return hubs.filter((hub) => agent.hub_ids.includes(hub.id));
    }
    return [];
  }, [agent, hubs]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>Agent Configuration</DialogTitle>
      <DialogContent>
        {agentLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Card sx={{ borderRadius: 2 }}>
            <CardContent>
              <Stack spacing={2}>
                <ErrorDisplay error={error} />
                <Typography variant="h5" fontWeight="bold">
                  {agent?.name ?? ""}
                </Typography>
                <Typography variant="body1" paragraph>
                  {agent?.description ?? ""}
                </Typography>
                <Divider />
                <Typography variant="h6" gutterBottom>
                  Connected Hubs
                </Typography>
                {(hubsLoading || hubsError) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      p: 2,
                    }}
                  >
                    {hubsLoading && <CircularProgress />}
                    {hubsError && <ErrorDisplay error={hubsError} />}
                  </Box>
                )}
                {!hubsLoading && !hubsError && connectedHubs.length > 0 ? (
                  <List disablePadding>
                    {connectedHubs.map((hub) => (
                      <ListItem key={hub.id} disableGutters>
                        <ListItemText
                          primary={hub.name}
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "0.9rem",
                            },
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    No hubs connected
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ViewAgentConfig;
