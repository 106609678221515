import * as React from "react";

import {
  Box,
  Chip,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import ToggleFavoriteButton from "../../components/ToggleFavoriteButton";
import { AiChatConfig, getChatConfig } from "../../components/chat/AiChatType";
import NiceDateAgo from "../../components/utils/NiceDateAgo";
import { AgentConfig, ChatSession, ResourceType } from "../../models/Models";
import { createFavoriteId } from "../../models/OrgUser";

function ChatSessionItem({ session, onClick }: Props) {
  const chatAgentConfig = null as unknown as AiChatConfig;
  // getChatConfig(session.agent_id);

  const navigate = useNavigate();

  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={() => {
          navigate(`/chat/${session.id}`);
          onClick();
        }}
      >
        <Stack direction={"column"} spacing={1} sx={{ width: "100%", mb: 2 }}>
          <Stack direction={"row"}>
            <ToggleFavoriteButton
              id={createFavoriteId(session.id, ResourceType.SESSION)}
            />
            <Typography
              variant={"h6"}
              sx={{ fontSize: "12pt" }}
              color={"text.primary"}
            >
              {session.name ? `Session ${session.name}` : "Unnamed session"}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ width: "100%" }}
          >
            <Box>
              <Chip
                variant="outlined"
                icon={chatAgentConfig?.icon}
                label={chatAgentConfig?.name}
                color={chatAgentConfig?.color}
              />
            </Box>
            <Typography variant={"subtitle2"} color={"text.secondary"}>
              <NiceDateAgo
                prefix={"Created "}
                date={session.created_at?.toDate()}
              />
            </Typography>
          </Stack>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
}

interface Props {
  readonly session: ChatSession;
  readonly onClick: () => void;
}

export default ChatSessionItem;
