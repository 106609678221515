import React from "react";

import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../../configs/firebase";

// Used to block rendering of child elements until the user is loaded. This helps prevent stutter/flicker rendering caused by guards.
function AuthLoadBlocker({ children }: React.PropsWithChildren): JSX.Element {
  const [, userLoading] = useAuthState(auth);

  if (userLoading) {
    return <></>;
  }

  return <>{children}</>;
}

export default AuthLoadBlocker;
