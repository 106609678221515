import React from "react";
import { useContext, useEffect, useState } from "react";

import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";

import AddFileToHub from "../../components/hubs/AddFileToHub";
import HubFileList from "../../components/hubs/HubFileList";
import AppPage from "../../components/navbar/AppPage";
import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import { HubContext } from "./HubContext";

const HubPage = () => {
  const { hubId } = useParams();
  const { selectedOrg } = useContext(OrgContext);
  const { hub, loading, error } = useContext(HubContext);
  const [editOpen, setEditOpen] = useState(false);
  const [editedName, setEditedName] = useState(hub?.name || "");
  const [editedDescription, setEditedDescription] = useState(
    hub?.description || ""
  );
  const maxDescriptionLength = 130;

  useEffect(() => {
    setEditedName(hub?.name || "");
    setEditedDescription(hub?.description || "");
  }, [hub]);

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleEditSave = async () => {
    try {
      if (hubId && selectedOrg) {
        await organization(selectedOrg)
          .hub(hubId)
          .update({ name: editedName, description: editedDescription });
      }
    } catch (error) {
      console.error("Error updating hub:", error);
    }
    setEditOpen(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography variant="body1">Error: {error.message}</Typography>;
  }

  return (
    <AppPage pageType={"flat"}>
      <Box sx={{ p: 3, width: "90%", mx: "auto" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
            {hub?.name}
          </Typography>
          {hub?.description && (
            <Typography variant="body1" color="text.secondary">
              {hub.description.length > maxDescriptionLength
                ? hub.description.substring(0, maxDescriptionLength) + "..."
                : hub.description}
            </Typography>
          )}
          <Tooltip title="Edit Knowledge Hub Name or Description">
            <IconButton onClick={handleEditOpen} aria-label="edit">
              <Edit />
            </IconButton>
          </Tooltip>
        </Stack>

        <AddFileToHub />
        <Paper
          sx={{
            p: 3,
            mt: 2,
            width: "100%",
            mx: "auto",
            borderRadius: 2,
            bgcolor: "grey.100",
            overflowY: "auto",
            height: "70vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={3}
            sx={{ flexGrow: 1 }}
          >
            <Box sx={{ flex: 1, overflowY: "auto" }}>
              <HubFileList orgId={selectedOrg} hubId={hubId as string} />
            </Box>
          </Stack>
        </Paper>
      </Box>
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Knowledge Hub</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Make changes to the hub name and description. Remember: the
            description is very important! Custom Agents use it to decide which
            Hub to search.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Hub Name"
            fullWidth
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={4}
            maxRows={8}
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </AppPage>
  );
};

export default HubPage;
