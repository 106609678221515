import { AgentConfig, OrgId } from "../../models/Models";
import { sendApiRequest } from "../utils/FetchUtils";

export async function startNewAgentSession(
  selectedOrg: OrgId,
  agentConfig: AgentConfig
): Promise<string> {
  const response = await sendApiRequest("/agents/new-session", {
    org_id: selectedOrg,
    agent_type: agentConfig.agent_type,
    agent_id: agentConfig.agent_id, // if mai this could be default, marketing, proposal. if custom then org/{}/agents/{*}
  });

  const data = await response.json();
  console.log("new session", data);
  return data.session_id; // Get the new sessionId
}

export async function sendMessage(
  selectedOrg: OrgId,
  sessionId: string,
  inputMessage: string
): Promise<void> {
  await sendApiRequest("/agents/send-message", {
    org_id: selectedOrg,
    session_id: sessionId,
    message: inputMessage,
  });
}
