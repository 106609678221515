import { sendApiRequest } from "../components/utils/FetchUtils";
import { AgentConfig, DocumentRequestType, OrgId } from "../models/Models";

interface AnalyzeDocumentRequest {
  readonly org_id: OrgId;
  readonly document_id: string;
  readonly agent_id: AgentConfig["agent_id"];
}

interface RunAnalyzerAnalyzeDocumentRequest extends AnalyzeDocumentRequest {
  readonly analyzer_id: string;
  readonly request_type: DocumentRequestType.RUN_ANALYZER;
}

interface AskQuestionAnalyzeDocumentRequest extends AnalyzeDocumentRequest {
  readonly request_message: string;
  readonly request_type: DocumentRequestType.QUESTION;
}

interface RunSimpleAnalyzeDocumentRequest extends AnalyzeDocumentRequest {
  readonly request_type: DocumentRequestType;
}

type AnalyzeDocumentRequestType =
  | RunAnalyzerAnalyzeDocumentRequest
  | AskQuestionAnalyzeDocumentRequest
  | RunSimpleAnalyzeDocumentRequest;

export async function analyzeDocument(
  request: AnalyzeDocumentRequestType
): Promise<void> {
  await sendApiRequest("/documents/analyze", request);
}
