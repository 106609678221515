import React, { useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import {
  AiChatConfig,
  getChatConfig,
} from "../../../components/chat/AiChatType";
import { AgentConfig, AgentType, MaiAgentId } from "../../../models/Models";
import ChatPickerDisplay from "../../chat/ChatPickerDisplay";

interface AnalyzerAgentPickerProps {
  selectedAgentType: AgentConfig;
  setSelectedAgentType: (config: AgentConfig) => void;
}

function AnalyzerAgentPicker({
  selectedAgentType,
  setSelectedAgentType,
}: AnalyzerAgentPickerProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const selectedAgent: AiChatConfig = null as unknown as AiChatConfig;
  // getChatConfig(selectedAgentType);

  function onClose() {
    setOpen(false);
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant={"outlined"}
        startIcon={selectedAgent.icon}
        endIcon={<ArrowDropDownIcon />}
        color={selectedAgent.color}
      >
        {selectedAgent.name}
      </Button>
      <Dialog open={open} onClose={onClose} fullWidth={true}>
        <DialogTitle>Select an Analyzer Agent</DialogTitle>
        <DialogContent>
          <Typography variant={"body2"} sx={{ mb: 2 }}>
            Agents determine which AI is run by the Analyzer and which quick
            actions are available
          </Typography>
          <ChatPickerDisplay
            selectChatType={(config: AgentConfig) => {
              setSelectedAgentType(config);
              onClose();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AnalyzerAgentPicker;
