/**
 *
 * ================================
 * ===== ACL ======================
 * ================================
 *
 */
import { Timestamp } from "firebase/firestore";

import { IFsModel } from "../fs/FirestoreUtils";
import { ColorType } from "../utils/AclUtils";
import { AclResource } from "./AclResource";

declare global {
  interface Error {
    code: string;
    safeMessage: string;
  }
}

export enum StatusEnum {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  READY = "READY", // hack for back compat
  ERROR = "ERROR", // hack for back compat
}

// "branded types" prevent two type aliases that both resolve to strings from resolving to each other
export type OrgId = string & { __brand: "org_id" };

export function makeOrgId(id: string): OrgId {
  return id as OrgId;
}

// "branded types" prevent two type aliases that both resolve to strings from resolving to each other
export type UserId = string & { __brand: "user_id" };

export function makeUserId(id: string | undefined): UserId {
  return id as UserId;
}

export interface Project extends IFsModel, AclResource {
  readonly name: string;
  readonly description: string;
  readonly created_at: Timestamp;
}

export interface ProjectQuestion extends IFsModel, AclResource {
  readonly question: string;
  readonly answer: string;
  readonly citation_sources: CitationSource[];
  readonly citations: Citation[];
  readonly status: StatusEnum;
  readonly description: string;
  readonly created_at: Timestamp;
}

export interface ProjectDocument extends IFsModel, AclResource {
  readonly bucket: string;
  readonly blob: string;
  readonly original_filename: string;
  // null if not yet summarized
  readonly summary?: string;
  readonly datastore_file_uri?: string;
  readonly datastore_status?: StatusEnum;
  readonly uploaded_at: Timestamp;
}

/**
 *
 * ================================
 * ===== ANALYZERS ================
 * ================================
 *
 */

export enum AnalyzerType {
  LEGAL = "LEGAL",
  CUSTOM = "CUSTOM",
}

export interface Analyzer extends IFsModel, AclResource {
  readonly name: string;
  // defined if sourced from the marketplace
  readonly marketplace_id?: string;
  // defined if sourced from the marketplace - used to detect updates
  readonly marketplace_version?: string;
  readonly type: AnalyzerType;
  readonly created_at: Timestamp;
}

export interface CustomAnalyzer extends Analyzer {
  readonly question: string;
}

export interface LegalAnalyzer extends Analyzer {
  readonly risk_factors: RiskFactor[];
}

export interface RiskFactor {
  readonly name: string;
  readonly description: string;
  readonly low_severity_if: string;
  readonly medium_severity_if: string;
  readonly high_severity_if: string;
}

/**
 *
 * =================================
 * ===== DOCUMENTS =================
 * =================================
 *
 */

/**
 * todo can we kill this?
 * @typedef {"LEGAL"|"PROPOSAL"|"REQUEST_FOR_PROPOSAL"|"MARKETING"|"OTHER"} ClassificationType
 */

export interface DocumentObj extends IFsModel, AclResource {
  readonly bucket: string;
  readonly blob: string;
  readonly original_filename: string;
  readonly uploaded_at: Timestamp;
}

export enum UserRole {
  USER = "USER",
  ADMIN = "ADMIN",
}

export interface Organization extends IFsModel<OrgId> {
  readonly roles: Record<UserId, UserRole>;
}

export enum AgentType {
  MAI = "mai",
  CUSTOM = "custom",
}

export enum MaiAgentId {
  DEFAULT = "default",
  MARKETING = "marketing",
  PROPOSAL = "proposal",
}

export type MaiAgentConfig = {
  agent_type: AgentType.MAI;
  agent_id: MaiAgentId;
};

export type CustomAgentConfig = {
  agent_type: AgentType.CUSTOM;
  agent_id: string;
};

export type AgentConfig = MaiAgentConfig | CustomAgentConfig;

export const MAI_DEFAULT_AGENT: MaiAgentConfig = {
  agent_type: AgentType.MAI,
  agent_id: MaiAgentId.DEFAULT,
};

export const MAI_AGENT_CONFIGS: MaiAgentConfig[] = [
  MAI_DEFAULT_AGENT,
  { agent_type: AgentType.MAI, agent_id: MaiAgentId.MARKETING },
  { agent_type: AgentType.MAI, agent_id: MaiAgentId.PROPOSAL },
];

// organizations/{orgId}/agents/{agentId}
export interface Agent extends IFsModel, AclResource {
  readonly name: string;
  readonly created_at: Timestamp;
  readonly hub_ids: string[];
  readonly is_legal: boolean;
  // following fields used to populate `AiChatConfig`
  readonly description?: string;
  readonly iconName?: string;
  readonly useCase?: string;
  readonly color?: ColorType;
}

export interface TestCitations {
  readonly insert_index: number;
  readonly title: string;
  readonly excerpt: string;
  readonly uri: string;
}

// organizations/{orgId}/hubs/{hub_id}
export interface KnowledgeHub extends IFsModel, AclResource {
  readonly name: string;
  readonly description: string;
  readonly rag_corpus_id: string | null;
  readonly created_at: Timestamp;
  readonly is_ingesting: boolean;
}

export enum ResourceType {
  DOCUMENT = "DOCUMENT",
  HUB = "HUB",
  PROJECT = "PROJECT",
  ANALYZER = "ANALYZER",
  QUESTION = "QUESTION",
  SESSION = "SESSION",
  USER = "USER",
  PROJECT_DOCUMENT = "PROJECT_DOCUMENT",
  AGENT = "AGENT",
}

// todo: deprecate. status is in message doc now.
export enum ChatSessionStatus {
  CREATING = "CREATING",
  SENDING = "SENDING",
  PENDING = "PENDING",
  STREAMING = "STREAMING",
  STREAMED = "STREAMED",
  READY = "READY",
  ERROR = "ERROR",
}

export type ChatSession = IFsModel &
  AgentConfig & {
    readonly created_at: Timestamp;
    readonly last_active: Timestamp;
    readonly name: string;
    readonly owner: string;
  };

export interface CitationSource {
  readonly uri: string;
  readonly title: string;
}

export interface Citation {
  readonly start_index: number;
  readonly end_index: number;
  readonly source_indices: number[];
  readonly text: string;
}

export interface QueryHubMetadata {
  readonly hub_id: string;
  readonly hub_name: string;
  readonly files_used: string[];
}

export interface ToolCallEvent {
  readonly tool: string;
  readonly tool_call_id: string;
  readonly tool_call_input_args: Map<string, any>;
  readonly tool_call_response: string;
  readonly tool_call_metadata: QueryHubMetadata;
}

export interface ChatMessage extends IFsModel, AclResource {
  readonly user_message: string;
  // null if model hasn't responded yet
  readonly model_message: string | null;
  readonly status: StatusEnum;
  readonly tools_used: ToolCallEvent[];
  // todo: deprecate citation_sources and citations
  readonly citation_sources: CitationSource[];
  readonly citations: Citation[];
  readonly error: string | null;
  readonly created_at: Timestamp;
}

export interface Invite extends IFsModel {
  readonly email: string;
  readonly role: UserRole;
  readonly created_at: Timestamp;
}

export enum DocumentRequestType {
  SUMMARIZE = "SUMMARIZE",
  PROOFREAD = "PROOFREAD",
  LEGAL_REVIEW = "LEGAL_REVIEW",
  QUESTION = "QUESTION",
  RUN_ANALYZER = "RUN_ANALYZER",
}

// todo make sure types are correct
export interface DocumentQuestion extends IFsModel, AclResource {
  // undefined if model hasn't responded yet
  readonly response?: string;
  // null for backwards compatibility
  readonly agent_id?: never;
  // defined if the request was to run an analyzer
  readonly analyzer_id?: string;
  readonly request_type: DocumentRequestType;
  // defined if request_type is QUESTION
  readonly request_message?: string;
  readonly status: StatusEnum;
  readonly created_at: Timestamp;
}
