import React, { useContext, useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import SharingDialog from "../../components/acls/SharingDialog";
import CreateAgentDialog from "../../components/agents/CreateAgentDialog";
import ViewAgentConfig from "../../components/agents/ViewAgentConfig";
import FsDeleteDialog from "../../components/dialogs/FsDeleteDialog";
import AppPage from "../../components/navbar/AppPage";
import FsTable, {
  TABLE_COL_CREATED_AT,
  TABLE_COL_OWNER,
} from "../../components/tables/FsTable";
import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import { Agent } from "../../models/Models";
import { whereReadAccessible } from "../../utils/AclUtils";
import { TableAction } from "../documents/list/DocumentGridControls";

function AgentsListPage() {
  const { selectedOrg, uid } = useContext(OrgContext);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [rowsToDelete, setRowsToDelete] = useState<Agent[]>([]);
  const [rowToShare, setRowToShare] = useState<Agent | null>(null);
  const [viewConfigOpen, setViewConfigOpen] = useState(false);
  const [agentToView, setAgentToView] = useState<string | null>(null);

  const actions = useMemo<TableAction<Agent>[]>(() => {
    const result: TableAction<Agent>[] = [
      {
        name: "Share",
        tooltip: "Share agent",
        supportsMultipleRows: false,
        icon: <PersonAddIcon />,
        action: (rows: Agent[]) => setRowToShare(rows[0]),
      },
      {
        name: "Delete",
        tooltip: "Delete agent",
        supportsMultipleRows: true,
        icon: <DeleteIcon />,
        action: (rows: Agent[]) => setRowsToDelete(rows),
      },
    ];
    return result;
  }, []);

  const AGENTS_TABLE_COL_NAME: GridColDef = {
    field: "name",
    headerName: "Name",
    flex: 1,
    width: 400,
    renderCell: (params) => {
      return (
        <Stack direction={"row"} spacing={1} alignItems="center">
          <IconButton
            onClick={(event) => {
              event.stopPropagation(); // dont select row on icon click
              setAgentToView(params.row.id);
              setViewConfigOpen(true);
            }}
          >
            <VisibilityIcon />
          </IconButton>
          <Typography>{params.value}</Typography>
        </Stack>
      );
    },
  };

  return (
    <AppPage pageType={"flat"}>
      <Typography variant="h4" gutterBottom>
        Custom Agents
      </Typography>
      <Box sx={{ mb: 3, maxWidth: "800px" }}>
        <Typography variant={"body2"}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </Typography>
      </Box>
      <Box sx={{ mb: 1 }} />
      <FsTable<Agent>
        columns={[AGENTS_TABLE_COL_NAME, TABLE_COL_OWNER, TABLE_COL_CREATED_AT]}
        enableSelection={true}
        enableControls={true}
        orderByDir={"desc"}
        actions={actions}
        extraControls={
          <Button
            variant="outlined"
            onClick={() => setCreateModalOpen(true)}
            startIcon={<AddIcon />}
          >
            New Custom Agent
          </Button>
        }
        dataCollection={organization(selectedOrg).agents()}
        whereClause={whereReadAccessible(uid)}
        orderByField={"created_at"}
      />
      <CreateAgentDialog
        isOpen={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
      <ViewAgentConfig
        open={viewConfigOpen}
        onClose={() => setViewConfigOpen(false)}
        agentId={agentToView ?? ""}
      />
      <FsDeleteDialog<Agent>
        title={"Delete Custom Agents"}
        rows={rowsToDelete}
        onClose={() => setRowsToDelete([])}
        collection={organization(selectedOrg).agents()}
        message={`Are you sure you want to delete ${rowsToDelete.length} custom agent(s)?`}
      />
      <SharingDialog
        open={rowToShare !== null}
        docRef={organization(selectedOrg).agent(rowToShare?.id)}
        resourceName={rowToShare?.name}
        onClose={() => setRowToShare(null)}
      />
    </AppPage>
  );
}

export default AgentsListPage;
