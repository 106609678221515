import React, { ReactNode, useEffect, useState } from "react";

import { QueryCompositeFilterConstraint } from "@firebase/firestore";
import { Box } from "@mui/material";
import {
  GridColDef,
  GridPaginationMeta,
  GridPaginationModel,
} from "@mui/x-data-grid";
import { QueryFieldFilterConstraint, Timestamp } from "firebase/firestore";

import { IFsModel } from "../../fs/FirestoreUtils";
import { FsCollectionRef } from "../../fs/FsCollectionRef";
import { UserId } from "../../models/Models";
import { TableAction } from "../../pages/documents/list/DocumentGridControls";
import UserAvatar from "../UserAvatar";
import WritersAvatarList from "../projects/WritersAvatarList";
import NiceDateAgo from "../utils/NiceDateAgo";
import BaseTable from "./BaseTable";
import usePaginatedCollection from "./usePaginatedCollection";

interface Props<T extends IFsModel> {
  readonly columns: GridColDef[];
  readonly enableSelection?: boolean;
  readonly dataCollection: FsCollectionRef<T>;
  readonly actions: TableAction<T>[];
  readonly pageSizeOptions?: number[];
  readonly defaultPageSize?: number;
  readonly extraControls?: ReactNode;
  readonly enableControls?: boolean;
  readonly orderByField: keyof T;
  readonly orderByDir: "asc" | "desc";
  readonly onCellDoubleClick?: (row: T) => void;
  readonly whereClause?:
    | QueryCompositeFilterConstraint
    | QueryFieldFilterConstraint;
  readonly dataGridProps?: any;
}

function FsTable<T extends IFsModel>({
  columns,
  enableSelection = true,
  dataCollection,
  defaultPageSize = 10,
  pageSizeOptions = [5, 10, 25, 100],
  enableControls = true,
  actions = [],
  extraControls,
  onCellDoubleClick,
  orderByField,
  whereClause = undefined,
  dataGridProps = {},
}: Props<T>) {
  const {
    totalCount,
    nextPage,
    prevPage,
    error,
    loading,
    pageNum,
    pageSize,
    setPageSize,
    hasNextPage,
    items,
  } = usePaginatedCollection(
    dataCollection,
    whereClause,
    defaultPageSize,
    orderByField,
    "desc"
  );

  const [debouncedItems, setDebouncedItems] = useState<T[]>(items);

  useEffect(() => {
    if (!loading) {
      setDebouncedItems(items);
    }
  }, [items, loading]);

  const paginationModel = {
    page: pageNum,
    pageSize: pageSize,
  };

  function onPaginationModelChange(model: GridPaginationModel) {
    console.log("page changed!");
    if (model.page !== pageNum) {
      if (model.page < pageNum) {
        prevPage();
      } else {
        nextPage();
      }
    }

    if (model.pageSize !== pageSize) {
      setPageSize(model.pageSize);
    }
  }

  const paginationMetaRef = React.useRef<GridPaginationMeta>({});
  // Memoize to avoid flickering when the `hasNextPage` is `undefined` during refetch
  const paginationMeta = React.useMemo(() => {
    if (
      hasNextPage !== undefined &&
      paginationMetaRef.current?.hasNextPage !== hasNextPage
    ) {
      paginationMetaRef.current = { hasNextPage };
    }
    return paginationMetaRef.current;
  }, [hasNextPage]);

  return (
    <BaseTable
      items={debouncedItems}
      enableSelection={enableSelection}
      extraControls={extraControls}
      enableControls={enableControls}
      error={error}
      actions={actions}
      onCellDoubleClick={onCellDoubleClick}
      dataGridProps={{
        columns: columns,
        loading: loading,
        // pagination
        paginationMode: "server",
        paginationMeta: paginationMeta,
        paginationModel: paginationModel,
        onPaginationModelChange: onPaginationModelChange,
        pageSizeOptions: pageSizeOptions,
        filterMode: "server",
        sortingMode: "server",
        rowCount: totalCount,
        ...dataGridProps,
      }}
    />
  );
}

export default FsTable;

export const TABLE_COL_CREATED_AT: GridColDef<any, Timestamp> = {
  field: "created_at",
  headerName: "Created At",
  width: 200,
  renderCell: (params) => <NiceDateAgo date={params.value} />,
};

export const TABLE_COL_OWNER: GridColDef<any, UserId> = {
  field: "owner",
  headerName: "Owner",
  renderCell: (params) => {
    return (
      <Box sx={{ mt: "6px" }}>
        <UserAvatar uid={params.value} />
      </Box>
    );
  },
};

export const TABLE_COL_WRITERS: GridColDef = {
  field: "writers",
  headerName: "Contributors",
  renderCell: (params) => {
    return (
      <Box sx={{ mt: "6px" }}>
        <WritersAvatarList project={params.row} />
      </Box>
    );
  },
};
