import { useContext, useEffect, useMemo, useState } from "react";

import { Timestamp } from "firebase/firestore";

import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import useFsCol from "../../fs/useFsCol";
import useFsDoc from "../../fs/useFsDoc";
import useChatConfig from "../../hooks/useChatConfig";
import {
  AgentConfig,
  ChatMessage,
  MaiAgentId,
  StatusEnum,
} from "../../models/Models";
import { AiChatConfig } from "./AiChatType";
import { sendMessage, startNewAgentSession } from "./ChatClient";

// todo: will need agentType (mai/custom) and agentId
interface ChatState {
  messages: ChatMessage[];
  sendMessage: (message: string) => Promise<string>;
  loading: boolean;
  agentConfig: AgentConfig;
  chatConfig: null | AiChatConfig;
  error: Error | null;
  sessionId: string | undefined;
}

// deprecate context. context used to be list project_ids. Now with custom agents
// the list of project_ids is tied to the agent itself.
function useAiChat(
  initialSessionId: string | undefined,
  agentConfig: AgentConfig
): ChatState {
  const { selectedOrg, uid } = useContext(OrgContext);

  const [isSending, setIsSending] = useState(false);
  const [pendingMessage, setPendingMessage] = useState("");
  const [isCreatingNewSession, setIsCreatingNewSession] = useState(false);

  const [sessionId, setSessionId] = useState<string | undefined>(
    initialSessionId
  );

  const chatConfigResult = useChatConfig(agentConfig);

  useEffect(() => {
    setSessionId(initialSessionId);
  }, [agentConfig, initialSessionId]);

  const [session, sessionLoading, sessionError] = useFsDoc(
    organization(selectedOrg).session(sessionId)
  );

  const [messages, loadingMessages, errorMessages] = useFsCol(
    organization(selectedOrg).session(sessionId).messages(),
    {
      orderBy: "created_at",
      orderDir: "asc",
    }
  );

  const lastMessageId =
    (messages ?? []).length > 0 ? messages![messages!.length - 1].id : "";

  useEffect(() => {
    setPendingMessage("");
  }, [lastMessageId]);

  async function handleSendMessage(message: string): Promise<string> {
    message = message.trim();
    if (message.length === 0) {
      return ""; // Don't send empty messages
    }

    setPendingMessage(message);
    setIsSending(true);

    try {
      const currentSessionId = sessionId ?? initialSessionId;

      if (!currentSessionId) {
        setIsCreatingNewSession(true);
        const newSessionId = await startNewAgentSession(
          selectedOrg!,
          agentConfig
        );
        setSessionId(newSessionId);
        setIsCreatingNewSession(false);
        await sendMessage(selectedOrg!, newSessionId, message);
        return newSessionId;
      } else {
        await sendMessage(selectedOrg!, currentSessionId, message);
        return currentSessionId;
      }
    } catch (error) {
      console.error("Error sending message:", error);
      throw error;
    } finally {
      setIsSending(false);
    }
  }

  const agent_id: string | MaiAgentId =
    session?.agent_id ?? agentConfig.agent_id ?? MaiAgentId.DEFAULT;

  const resultMessages: ChatMessage[] = useMemo(() => {
    if (pendingMessage) {
      const fake: ChatMessage = {
        id: "fake",
        user_message: pendingMessage,
        model_message: null,
        status: StatusEnum.PENDING,
        tools_used: [],
        citation_sources: [],
        citations: [],
        error: null,
        created_at: Timestamp.now(),
        allowOrgRead: false,
        allowOrgWrite: false,
        owner: uid,
        readers: [uid],
        writers: [uid],
      };
      return [...(messages ?? []), fake];
    }

    return messages ?? [];
  }, [pendingMessage, messages]);

  const backendError = useMemo(() => {
    return resultMessages.some((message) => message.error)
      ? new Error("Something went wrong. Please try again.")
      : null;
  }, [resultMessages]);

  return {
    messages: resultMessages,
    agentConfig,
    chatConfig: chatConfigResult.value ?? null,
    sessionId,
    loading: sessionLoading || loadingMessages,
    error: null,
    sendMessage: handleSendMessage,
  };
}

export default useAiChat;
