import React, { useContext, useMemo, useState } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DocumentReference, Timestamp, deleteDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import ErrorDisplay from "../../components/utils/ErrorDisplay";
import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import { KnowledgeHub } from "../../models/Models";
import KnowledgeHubClient from "./HubClient";

const client = new KnowledgeHubClient();

interface CreateHubDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateHubDialog: React.FC<CreateHubDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const { selectedOrg, uid } = useContext(OrgContext);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigate();
  const isNameValid = useMemo(() => name.trim().length > 3, [name]);

  const handleCreate = async () => {
    if (isLoading || !isNameValid) {
      return;
    }

    setIsLoading(true);

    const HubDoc: KnowledgeHub = {
      id: "",
      name,
      description,
      rag_corpus_id: null,
      is_ingesting: false,
      owner: uid,
      readers: [uid],
      writers: [uid],
      allowOrgRead: false,
      allowOrgWrite: false,
      created_at: Timestamp.now(),
    };

    let result: DocumentReference | undefined = undefined;
    try {
      result = await organization(selectedOrg).hubs().create(HubDoc);
      await client.createHub(selectedOrg, result.id);

      navigate(`/hubs/${result.id}`);
      onClose();
    } catch (e) {
      setError(e as Error);

      try {
        if (result) {
          await deleteDoc(result);
        }
      } catch (deleteError) {
        console.error("Error deleting document:", deleteError);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event: React.FormEvent<HTMLInputElement>) => {
          event.preventDefault();
          await handleCreate();
        },
      }}
    >
      <DialogTitle>New Knowledge Hub</DialogTitle>
      <DialogContent>
        <DialogContentText>Create a new Knowledge Hub.</DialogContentText>
        <ErrorDisplay error={error} />
        <TextField
          autoFocus
          required
          label="Name"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          type="text"
          sx={{ mb: 3, mt: 2 }}
          fullWidth
        />
        <TextField
          label="Description"
          multiline
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          rows={4}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" disabled={isLoading || !isNameValid}>
          {isLoading ? (
            <>
              Setting things up... <CircularProgress size={20} />
            </>
          ) : (
            "Create"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateHubDialog;
