import React, { useContext, useMemo, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { Box, Card, Stack, Typography } from "@mui/material";

import IconMenu from "../../../components/IconMenu";
import {
  AiChatConfig,
  getChatConfig,
} from "../../../components/chat/AiChatType";
import ChatInputBox from "../../../components/chat/ChatInputBox";
import ErrorDisplay from "../../../components/utils/ErrorDisplay";
import { OrgContext } from "../../../contexts/OrgContext";
import { useWorkload } from "../../../hooks/useWorkload";
import {
  AgentConfig,
  AgentType,
  DocumentObj,
  DocumentRequestType,
  MaiAgentId,
} from "../../../models/Models";
import { analyzeDocument } from "../../../utils/ApiClient";
import DocumentRenameModal from "../list/DocumentRenameModal";
import AnalyzeQuestionsDisplay from "./AnalyzeQuestionsDisplay";
import AnalyzerAgentPicker from "./AnalyzerAgentPicker";
import AnalyzerQuickActionsBar from "./AnalyzerQuickActionsBar";

interface AnalyzePanelProps {
  document: DocumentObj | null;
}

const AnalyzePanel: React.FC<AnalyzePanelProps> = ({ document }) => {
  const { selectedOrg } = useContext(OrgContext);
  const [sending, error, runWorkload] = useWorkload([]);
  const [renameOpen, setRenameOpen] = useState<boolean>(false);
  const [selectedAgent, setSelectedAgent] = useState<AgentConfig>({
    agent_type: AgentType.MAI,
    agent_id: MaiAgentId.DEFAULT,
  });

  async function handleAnalyzeQuestion(message: string): Promise<void> {
    runWorkload(async () => {
      await analyzeDocument({
        org_id: selectedOrg,
        document_id: document!.id,
        agent_id: selectedAgent.agent_id,
        request_type: DocumentRequestType.QUESTION,
        request_message: message,
      });
    });
  }

  // TODO: replace with useChatConfig hook
  const selectedAgentConfig: AiChatConfig = useMemo(
    () => null as unknown as AiChatConfig, // getChatConfig(selectedAgentType),
    [selectedAgent]
  );

  return (
    <Box
      sx={{ height: "100%", pt: 2, pr: 2, pl: 2 }}
      display="flex"
      flexDirection="column"
    >
      <Card sx={{ p: 2 }}>
        <Stack direction={"row"} sx={{ mb: 2 }} alignItems={"center"}>
          <Typography
            variant={"overline"}
            sx={{ pr: 2, fontWeight: 800 }}
            color={selectedAgentConfig.color}
          >
            Agent Type
          </Typography>
          <AnalyzerAgentPicker
            selectedAgentType={selectedAgent}
            setSelectedAgentType={setSelectedAgent}
          />
          <Box sx={{ flexGrow: 1 }} />
          <IconMenu
            id={"doc-menu"}
            menuItems={[
              {
                name: "Rename Document",
                icon: <EditIcon />,
                action: () => setRenameOpen(true),
              },
            ]}
          />
        </Stack>
        <AnalyzerQuickActionsBar
          document={document}
          agentConfig={selectedAgent}
          disabled={sending}
          sendRequest={runWorkload}
        />
        <ErrorDisplay error={error} />
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <ChatInputBox
            sendMessage={(msg) => handleAnalyzeQuestion(msg)}
            isBusy={sending}
            color={selectedAgentConfig.color}
            label={
              "Ask Question (e.g. rewrite section 2.1 but using active voice)"
            }
            sx={{ maxWidth: "100%" }}
          />
        </Stack>
      </Card>
      <AnalyzeQuestionsDisplay document={document} />
      <DocumentRenameModal
        document={document}
        isOpen={renameOpen}
        onClose={() => setRenameOpen(false)}
      />
    </Box>
  );
};

export default AnalyzePanel;
