import { Timestamp } from "firebase/firestore";

import {
  Agent,
  Analyzer,
  ChatMessage,
  ChatSession,
  DocumentObj,
  DocumentQuestion,
  Invite,
  KnowledgeHub,
  OrgId,
  Organization,
  Project,
  ProjectDocument,
  ProjectQuestion,
  ResourceType,
} from "../models/Models";
import { OrgUser } from "../models/OrgUser";
import { RootUser } from "../models/RootUser";
import { FsCollectionRef } from "./FsCollectionRef";
import { FsDocRef } from "./FsDocRef";

export interface IFsModel<I extends string = string> {
  readonly id: I;
}

export interface ICreatedAt {
  readonly created_at: Timestamp;
}

export function organization(id: OrgId | null): OrganizationRef {
  return OrganizationsCol.instance.get(id);
}

// =======================================
// ORGANIZATIONS

export class OrganizationsCol extends FsCollectionRef<
  Organization,
  OrganizationRef
> {
  public static readonly instance: OrganizationsCol = new OrganizationsCol();

  private constructor() {
    super(null, "organizations", null);
  }

  get(id: string | null | undefined): OrganizationRef {
    return new OrganizationRef(this, id ?? null);
  }
}

export class OrganizationRef extends FsDocRef<Organization> {
  agent(id: string | null | undefined): AgentsRef {
    return new AgentsCol(this).get(id ?? null);
  }

  agents(): AgentsCol {
    return new AgentsCol(this);
  }

  analyzers(): AnalyzersCol {
    return new AnalyzersCol(this);
  }

  analyzer(id: string | null | undefined): AnalyzersRef {
    return new AnalyzersCol(this).get(id ?? null);
  }

  hubs(): KnowledgeHubsCol {
    return new KnowledgeHubsCol(this);
  }

  hub(id: string | null | undefined): KnowledgeHubsRef {
    return new KnowledgeHubsCol(this).get(id ?? null);
  }

  projects(): ProjectsCol {
    return new ProjectsCol(this);
  }

  project(id: string | null | undefined): ProjectRef {
    return new ProjectsCol(this).get(id ?? null);
  }

  documents(): DocumentsCol {
    return new DocumentsCol(this);
  }

  document(id: string | null | undefined): DocumentRef {
    return new DocumentsCol(this).get(id ?? null);
  }

  users(): OrgUsersCol {
    return new OrgUsersCol(this);
  }

  user(id: string | null | undefined): OrgUserRef {
    return new OrgUsersCol(this).get(id ?? null);
  }

  sessions(): SessionsCol {
    return new SessionsCol(this);
  }

  session(id: string | null | undefined): SessionRef {
    return new SessionsCol(this).get(id ?? null);
  }

  invites(): InvitesCol {
    return new InvitesCol(this);
  }

  invite(id: string | null | undefined): InviteRef {
    return new InvitesCol(this).get(id ?? null);
  }
}

// =======================================
// AGENTS

export class AgentsCol extends FsCollectionRef<Agent, AgentsRef> {
  constructor(parent: FsDocRef<any> | null) {
    super(parent, "agents", ResourceType.AGENT);
  }

  override get(id: string | null | undefined): AgentsRef {
    return new AgentsRef(this, id ?? null);
  }
}

export class AgentsRef extends FsDocRef<Agent> {}

// =======================================
// ANALYZERS

export class AnalyzersCol extends FsCollectionRef<Analyzer, AnalyzersRef> {
  constructor(parent: FsDocRef<any> | null) {
    super(parent, "analyzers", ResourceType.ANALYZER);
  }

  override get(id: string | null | undefined): AnalyzersRef {
    return new AnalyzersRef(this, id ?? null);
  }
}

export class AnalyzersRef extends FsDocRef<Analyzer> {}

// =======================================
// Knowledge Hubs

export class KnowledgeHubsCol extends FsCollectionRef<
  KnowledgeHub,
  KnowledgeHubsRef
> {
  constructor(parent: FsDocRef<any> | null) {
    super(parent, "hubs", ResourceType.HUB);
    console.log(this);
  }

  override get(id: string | null | undefined): KnowledgeHubsRef {
    return new KnowledgeHubsRef(this, id ?? null);
  }
}

export class KnowledgeHubsRef extends FsDocRef<KnowledgeHub> {}

// =======================================
// PROJECTS
export class ProjectsCol extends FsCollectionRef<Project, ProjectRef> {
  constructor(parent: FsDocRef<any> | null) {
    super(parent, "projects", ResourceType.PROJECT);
  }

  override get(id: string | null | undefined): ProjectRef {
    return new ProjectRef(this, id ?? null);
  }
}

export class ProjectRef extends FsDocRef<Project> {
  documents(): ProjectDocumentsCol {
    return new ProjectDocumentsCol(this);
  }

  document(id: string | null | undefined): ProjectDocumentRef {
    return new ProjectDocumentsCol(this).get(id ?? null);
  }

  questions(): ProjectQuestionsCol {
    return new ProjectQuestionsCol(this);
  }

  question(id: string | null | undefined): ProjectQuestionRef {
    return new ProjectQuestionsCol(this).get(id);
  }
}

// =======================================
// Org Users
export class OrgUsersCol extends FsCollectionRef<OrgUser, OrgUserRef> {
  constructor(parent: FsDocRef<any> | null) {
    super(parent, "users", ResourceType.USER);
  }

  override get(id: string | null | undefined): OrgUserRef {
    return new OrgUserRef(this, id ?? null);
  }
}

export class OrgUserRef extends FsDocRef<OrgUser> {}

// =======================================
// Root Users
export class RootUsersCol extends FsCollectionRef<RootUser, RootUserRef> {
  public static readonly instance: RootUsersCol = new RootUsersCol();

  private constructor() {
    super(null, "users", ResourceType.USER);
  }

  override get(id: string | null | undefined): RootUserRef {
    return new RootUserRef(this, id ?? null);
  }
}

export class RootUserRef extends FsDocRef<RootUser> {}

// =======================================
// Project Documents
export class ProjectDocumentsCol extends FsCollectionRef<
  ProjectDocument,
  ProjectDocumentRef
> {
  constructor(parent: FsDocRef<any> | null) {
    super(parent, "documents", ResourceType.DOCUMENT);
  }

  override get(id: string | null | undefined): ProjectDocumentRef {
    return new ProjectDocumentRef(this, id ?? null);
  }
}

export class ProjectDocumentRef extends FsDocRef<ProjectDocument> {}

// =======================================
// Project Documents
export class DocumentsCol extends FsCollectionRef<DocumentObj, DocumentRef> {
  constructor(parent: FsDocRef<any> | null) {
    super(parent, "documents", ResourceType.PROJECT_DOCUMENT);
  }

  override get(id: string | null | undefined): DocumentRef {
    return new DocumentRef(this, id ?? null);
  }
}

export class DocumentRef extends FsDocRef<DocumentObj> {
  requests(): DocumentRequestsCol {
    return new DocumentRequestsCol(this);
  }

  request(id: string | null | undefined): DocumentRequestRef {
    return new DocumentRequestsCol(this).get(id);
  }
}

// =======================================
// Sessions
export class SessionsCol extends FsCollectionRef<ChatSession, SessionRef> {
  constructor(parent: FsDocRef<any> | null) {
    super(parent, "sessions", ResourceType.SESSION);
  }

  override get(id: string | null | undefined): SessionRef {
    return new SessionRef(this, id ?? null);
  }
}

export class SessionRef extends FsDocRef<ChatSession> {
  messages(): MessagesCol {
    return new MessagesCol(this);
  }

  message(id: string | null | undefined): MessageRef {
    return new MessagesCol(this).get(id ?? null);
  }
}

// =======================================
// Session Messages
export class MessagesCol extends FsCollectionRef<ChatMessage, MessageRef> {
  constructor(parent: FsDocRef<any> | null) {
    super(parent, "messages", null);
  }

  override get(id: string | null | undefined): MessageRef {
    return new MessageRef(this, id ?? null);
  }
}

export class MessageRef extends FsDocRef<ChatMessage> {}

// =======================================
// Invites
export class InvitesCol extends FsCollectionRef<Invite, InviteRef> {
  constructor(parent: FsDocRef<any> | null) {
    super(parent, "invites", null);
  }

  override get(id: string | null | undefined): InviteRef {
    return new InviteRef(this, id ?? null);
  }
}

export class InviteRef extends FsDocRef<Invite> {}

// =======================================
// Invites
export class DocumentRequestsCol extends FsCollectionRef<
  DocumentQuestion,
  DocumentRequestRef
> {
  constructor(parent: FsDocRef<any> | null) {
    super(parent, "requests", null);
  }

  override get(id: string | null | undefined): DocumentRequestRef {
    return new DocumentRequestRef(this, id ?? null);
  }
}

export class DocumentRequestRef extends FsDocRef<DocumentQuestion> {}

// =======================================
// Project Questions
export class ProjectQuestionsCol extends FsCollectionRef<
  ProjectQuestion,
  ProjectQuestionRef
> {
  constructor(parent: FsDocRef<any> | null) {
    super(parent, "questions", null);
  }

  override get(id: string | null | undefined): ProjectQuestionRef {
    return new ProjectQuestionRef(this, id ?? null);
  }
}

export class ProjectQuestionRef extends FsDocRef<ProjectQuestion> {}
