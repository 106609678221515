import React, { useContext, useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Box, Button, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";

import ToggleFavoriteButton from "../../components/ToggleFavoriteButton";
import SharingDialog from "../../components/acls/SharingDialog";
import RenameDialog from "../../components/dialogs/RenameDialog";
import CreateHubDialog from "../../components/hubs/CreateHubModal";
import DeleteHubModal from "../../components/hubs/DeleteHubModal";
import AppPage from "../../components/navbar/AppPage";
import FsTable, {
  TABLE_COL_CREATED_AT,
  TABLE_COL_OWNER,
  TABLE_COL_WRITERS,
} from "../../components/tables/FsTable";
import { fixHref } from "../../components/utils/Utils";
import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import { KnowledgeHub, ResourceType } from "../../models/Models";
import { createFavoriteId } from "../../models/OrgUser";
import { whereReadAccessible } from "../../utils/AclUtils";
import { TableAction } from "../documents/list/DocumentGridControls";

function HubListPage() {
  const { selectedOrg, uid } = useContext(OrgContext);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [rowsToDelete, setRowsToDelete] = useState<KnowledgeHub[]>([]);
  const [rowToShare, setRowToShare] = useState<KnowledgeHub | null>(null);
  const [rowToRename, setRowToRename] = useState<KnowledgeHub | null>(null);

  const actions = useMemo(() => {
    const result: TableAction<KnowledgeHub>[] = [
      {
        name: "Rename",
        tooltip: "rename hub",
        supportsMultipleRows: false,
        icon: <EditIcon />,
        action: (rows: KnowledgeHub[]) => setRowToRename(rows[0]),
      },
      {
        name: "Share",
        tooltip: "share hub",
        supportsMultipleRows: false,
        icon: <PersonAddIcon />,
        action: (rows) => setRowToShare(rows[0]),
      },
      {
        name: "Delete",
        tooltip: "Delete Knowledge Hub",
        supportsMultipleRows: true,
        icon: <DeleteIcon />,
        action: (rows) => setRowsToDelete(rows),
      },
    ];
    return result;
  }, []);

  return (
    <AppPage pageType={"flat"}>
      <Typography variant="h4" gutterBottom>
        Knowledge Hubs
      </Typography>
      <Box sx={{ mb: 3, maxWidth: "800px" }}>
        <Typography variant={"body2"}>
          Get insightful answers from your documents with your Knowledge Hub
          Specific AI. Each Knowledge Hub can support up to 10,000 documents!
          Create a Knowledge Hub, add your documents, and wait for them to be
          Ready. Once ready, click the Knowledge Hub AI button to ask your AI
          any question and get answers that synthesize and cite information
          directly from your documents. Manage Knowledge Hub access with ease.
        </Typography>
      </Box>
      <Box sx={{ mb: 1 }} />
      <FsTable<KnowledgeHub>
        columns={[
          HUB_TABLE_COL_NAME,
          TABLE_COL_OWNER,
          TABLE_COL_WRITERS,
          TABLE_COL_CREATED_AT,
        ]}
        enableSelection={true}
        enableControls={true}
        orderByDir={"desc"}
        actions={actions}
        extraControls={
          <Button
            variant="outlined"
            onClick={() => setCreateModalOpen(true)}
            startIcon={<AddIcon />}
          >
            New Knowledge Hub
          </Button>
        }
        dataCollection={organization(selectedOrg).hubs()}
        whereClause={whereReadAccessible(uid)}
        orderByField={"created_at"}
        onCellDoubleClick={(row) => {
          navigate(`/projects/${row.id}`);
        }}
      />
      <CreateHubDialog
        isOpen={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
      <DeleteHubModal
        isOpen={rowsToDelete.length > 0}
        onClose={() => setRowsToDelete([])}
        selectedHubs={rowsToDelete}
      />
      <SharingDialog
        open={rowToShare !== null}
        docRef={organization(selectedOrg).hub(rowToShare?.id)}
        resourceName={rowToShare?.name}
        onClose={() => setRowToShare(null)}
      />
      <RenameDialog<KnowledgeHub>
        isOpen={rowToRename != null}
        onClose={() => setRowToRename(null)}
        title={"Rename Knowledge Hub"}
        originalName={rowToRename?.name}
        collection={organization(selectedOrg).hub(rowToRename?.id)}
        createMutation={(name) => ({ name: name })}
      />
    </AppPage>
  );
}

export default HubListPage;

export const HUB_TABLE_COL_NAME: GridColDef = {
  field: "name",
  headerName: "Name",
  flex: 1,
  width: 400,
  renderCell: (params) => {
    return (
      <Stack direction={"row"} spacing={1}>
        <ToggleFavoriteButton
          id={createFavoriteId(params.row.id, ResourceType.HUB)}
        />
        <Link to={fixHref(`/hubs/${params.row.id}`)}>{params.value}</Link>
      </Stack>
    );
  },
};

export const BASE_PROJECTS_TABLE_COL_ACTIONS: GridColDef = {
  field: "actions",
  type: "actions",
  width: 80,
};
