import React, { useContext } from "react";

import { Typography } from "@mui/material";

import { OrgContext } from "../../contexts/OrgContext";
import { KnowledgeHub } from "../../models/Models";
import ConfirmDeleteDialog from "../dialogs/ConfirmDeleteDialog";
import KnowledgeHubClient from "./HubClient";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedHubs: KnowledgeHub[];
}

const DeleteHubModal: React.FC<Props> = ({ isOpen, onClose, selectedHubs }) => {
  const { selectedOrg } = useContext(OrgContext);
  const client = new KnowledgeHubClient();

  const handleConfirmDelete = async () => {
    try {
      await Promise.all(
        selectedHubs.map(async (hub) => {
          return client.deleteHub(selectedOrg, hub.id);
        })
      );
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error("Error deleting hubs:", error);
    }
  };

  return (
    <ConfirmDeleteDialog
      title="Confirm Delete"
      message={
        <Typography sx={{ mt: 2 }}>
          Are you sure you want to delete {selectedHubs.length} knowledge hubs?
        </Typography>
      }
      open={isOpen}
      onClose={onClose}
      onDelete={handleConfirmDelete}
    />
  );
};

export default DeleteHubModal;
