import React, { useContext, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Stack, Typography } from "@mui/material";
import { StorageReference, ref, uploadBytesResumable } from "firebase/storage";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";

import { storage } from "../../configs/firebase";
import { OrgContext } from "../../contexts/OrgContext";
import { HubContext } from "../../pages/hubs/HubContext";
import { canWrite, canWriteOrDie } from "../../utils/AclUtils";
import KnowledgeHubClient from "./HubClient";

const ALLOWED_TYPES: { [key: string]: string } = {
  "application/pdf": ".pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    ".docx",
  "text/markdown": ".md",
};

function AddFileToHub(): JSX.Element {
  const { selectedOrg, uid } = useContext(OrgContext);
  const { hub } = useContext(HubContext);
  const { hubId } = useParams();
  const navigate = useNavigate();
  const client = new KnowledgeHubClient();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (hub?.is_ingesting) {
      setUploading(true);
    } else {
      setUploading(false);
    }
  }, [hub?.is_ingesting]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!hub || !hubId) return;

    canWriteOrDie(hub, uid);

    if (e.target.files) {
      setUploading(true);

      const gcsPaths: string[] = [];
      const uploadPromises: Promise<void>[] = [];

      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        const storageRef: StorageReference = ref(
          storage,
          `${selectedOrg}/${hubId}/${file.name}`
        );
        const gcsPath = `gs://${storageRef.bucket}/${storageRef.fullPath}`;
        gcsPaths.push(gcsPath);

        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadPromises.push(
          new Promise<void>((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
              },
              (error) => {
                console.error("Error uploading file:", error);
                reject(error);
              },
              () => {
                resolve();
              }
            );
          })
        );
      }

      try {
        await Promise.all(uploadPromises);
        await client.ingestFiles(selectedOrg, hubId, gcsPaths);
        navigate(`/hubs/${hubId}`);
      } catch (error) {
        console.error("Error ingesting files:", error);
        // Handle error, e.g., show an error message
      } finally {
        setUploading(false);
        setProgress(0);
      }
    }
  };

  const canUpload = hub ? canWrite(hub, uid) : true;

  return (
    <Box>
      <input
        type="file"
        accept={Object.values(ALLOWED_TYPES).join(", ")}
        multiple
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="add-file-to-hub-button"
      />
      <label htmlFor="add-file-to-hub-button">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button
            variant="outlined"
            color="secondary"
            component="span"
            disabled={uploading || !canUpload}
            startIcon={<AddIcon />}
          >
            Add File
          </Button>
          {uploading && (
            <>
              <ThreeDots
                height="20"
                width="20"
                radius="2"
                color="#000000"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <Typography variant="body2" color="text.secondary">
                Ingesting...
              </Typography>
            </>
          )}
        </Stack>
      </label>
    </Box>
  );
}

export default AddFileToHub;
