import React, { useContext, useMemo, useState } from "react";

import ChecklistIcon from "@mui/icons-material/Checklist";
import GavelIcon from "@mui/icons-material/Gavel";
import GradingIcon from "@mui/icons-material/Grading";
import MessageIcon from "@mui/icons-material/Message";
import { Box, Chip, Stack, Tooltip, Typography } from "@mui/material";

import {
  AiChatConfig,
  getChatConfig,
} from "../../../components/chat/AiChatType";
import { OrgContext } from "../../../contexts/OrgContext";
import { WorkloadRunner } from "../../../hooks/useWorkload";
import {
  AgentConfig,
  AnalyzerType,
  DocumentObj,
  DocumentRequestType,
  MAI_DEFAULT_AGENT,
} from "../../../models/Models";
import { analyzeDocument } from "../../../utils/ApiClient";
import AnalyzerPicker from "./AnalyzerPicker";

interface QuickActionProps {
  name: string;
  tip: string;
  color: "primary" | "secondary";
  action: () => void;
  supportedAgents: AgentConfig[];
  icon: JSX.Element;
}

interface AnalyzerQuickActionsBarProps {
  document: DocumentObj | null;
  agentConfig: AgentConfig;
  disabled: boolean;
  sendRequest: WorkloadRunner;
}

function AnalyzerQuickActionsBar({
  document,
  agentConfig,
  disabled,
  sendRequest,
}: AnalyzerQuickActionsBarProps): JSX.Element {
  const [legalAnalyzerOpen, setLegalAnalyzerOpen] = useState<boolean>(false);
  const [customAnalyzerOpen, setCustomAnalyzerOpen] = useState<boolean>(false);
  const { selectedOrg } = useContext(OrgContext);

  async function handleRunAnalyzer(analyzer: { id: string }): Promise<void> {
    sendRequest(() =>
      analyzeDocument({
        org_id: selectedOrg,
        document_id: document!.id,
        agent_id: agentConfig.agent_id,
        request_type: DocumentRequestType.RUN_ANALYZER,
        analyzer_id: analyzer.id,
      })
    );
  }

  async function handleAnalyze(type: DocumentRequestType): Promise<void> {
    sendRequest(() =>
      analyzeDocument({
        org_id: selectedOrg,
        document_id: document!.id,
        agent_id: agentConfig.agent_id,
        request_type: type,
      })
    );
  }

  const quickActions: QuickActionProps[] = useMemo(() => {
    return [
      {
        name: "Proofread",
        tip: "Mysticetus AI will proofread the document for potential issues.",
        action: () => handleAnalyze(DocumentRequestType.PROOFREAD),
        icon: <ChecklistIcon sx={{}} />,
        supportedAgents: [MAI_DEFAULT_AGENT],
        color: "primary",
      },
      {
        name: "Summarize",
        tip: "Mysticetus AI will summarize the document concisely in layman's terms.",
        icon: <MessageIcon sx={{ fontSize: "18px" }} />,
        action: () => handleAnalyze(DocumentRequestType.SUMMARIZE),
        supportedAgents: [MAI_DEFAULT_AGENT],
        color: "primary",
      },
      {
        name: "Custom Analyzer",
        tip: "Run one of your custom analyzers against this document.",
        icon: <GradingIcon sx={{ fontSize: "16px" }} />,
        action: () => setCustomAnalyzerOpen(true),
        supportedAgents: [MAI_DEFAULT_AGENT],
        color: "secondary",
      },
      {
        name: "Legal Analyzer",
        tip: "Use Mysticetus' custom Legal Review AI to review the document and provide suggestions.",
        icon: <GavelIcon sx={{ fontSize: "16px" }} />,
        action: () => setLegalAnalyzerOpen(true),
        supportedAgents: [MAI_DEFAULT_AGENT],
        color: "secondary",
      },
    ];
  }, [agentConfig]);

  const selectedAgentConfig: AiChatConfig = null as unknown as AiChatConfig;

  //  useMemo(() => getChatConfig(agentType), [agentType]);

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography variant={"overline"} color={selectedAgentConfig.color}>
          Quick Actions
        </Typography>
        <Stack direction={"row"} spacing={2}>
          {quickActions
            .filter((action) => action.supportedAgents.includes(agentConfig))
            .map((action) => {
              return (
                <Tooltip title={action.tip} key={action.name}>
                  <Chip
                    variant="outlined"
                    color={selectedAgentConfig.color}
                    sx={{ pl: 1 }}
                    icon={action.icon}
                    onClick={action.action}
                    disabled={disabled}
                    label={action.name}
                  />
                </Tooltip>
              );
            })}
        </Stack>
      </Box>
      <AnalyzerPicker
        title={"Select a Legal Analyzer"}
        analyzerType={AnalyzerType.LEGAL}
        open={legalAnalyzerOpen}
        onClose={() => setLegalAnalyzerOpen(false)}
        callback={handleRunAnalyzer}
      />
      <AnalyzerPicker
        title={"Select a Custom Analyzer"}
        analyzerType={AnalyzerType.CUSTOM}
        open={customAnalyzerOpen}
        onClose={() => setCustomAnalyzerOpen(false)}
        callback={handleRunAnalyzer}
      />
    </>
  );
}

export default AnalyzerQuickActionsBar;
