import React, { createContext, useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { OrgContext } from "../../contexts/OrgContext";
import { organization } from "../../fs/FirestoreUtils";
import useFsDoc from "../../fs/useFsDoc";
import { KnowledgeHub } from "../../models/Models";

interface HubContextType {
  hub: KnowledgeHub | null;
  loading: boolean;
  error: Error | null;
}

const HubContext = createContext<HubContextType>({
  hub: null,
  loading: true,
  error: null,
});

interface HubProviderProps {
  children: React.ReactNode;
}

const HubProvider: React.FC<HubProviderProps> = ({ children }) => {
  const { hubId } = useParams();
  const { selectedOrg } = useContext(OrgContext);

  const [hub, loading, error] = useFsDoc(organization(selectedOrg).hub(hubId));

  return (
    <HubContext.Provider value={{ hub, loading, error }}>
      {children}
    </HubContext.Provider>
  );
};

export { HubContext, HubProvider };
