import React, { ReactNode, useContext, useMemo, useState } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LaunchIcon from "@mui/icons-material/Launch";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { Stack, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";

import { GlobalSnackbarContext } from "../../../components/GlobalSnackbarContext";
import { IconMenuItem } from "../../../components/IconMenu";
import QuestionAndResponseCard from "../../../components/QuestionAndResponseCard";
import CreateCustomAnalyzerDialog from "../../../components/documents/CreateCustomAnalyzerDialog";
import useTextSmooth from "../../../components/useTextSmooth";
import { OrgContext } from "../../../contexts/OrgContext";
import { organization } from "../../../fs/FirestoreUtils";
import useFsDoc from "../../../fs/useFsDoc";
import {
  AnalyzerType,
  CustomAnalyzer,
  DocumentObj,
  DocumentQuestion,
  DocumentRequestType,
  MaiAgentId,
  StatusEnum,
} from "../../../models/Models";
import EditCustomAnalyzerDialog from "../../analyzers/edit/EditCustomAnalyzerDialog";

const requestTypeMap: Record<string, string> = {
  SUMMARIZE: "Summarize",
  PROOFREAD: "Proofread",
  LEGAL_REVIEW: "Legal Review",
  QUESTION: "Question",
};

interface DocumentQuestionCardProps {
  document: DocumentObj;
  data: DocumentQuestion;
}

function DocumentQuestionCard({
  document,
  data,
}: DocumentQuestionCardProps): React.JSX.Element {
  const { created_at, request_type, response, request_message, status } = data;
  const { selectedOrg } = useContext(OrgContext);
  const { showSnackbar } = useContext(GlobalSnackbarContext);
  const [showAnalyzerDialog, setShowAnalyzerDialog] = useState<boolean>(false);

  const loading = status === StatusEnum.PROCESSING;
  const error =
    status === StatusEnum.ERROR
      ? new Error(
          "There was an error processing your request. Please try again."
        )
      : null;

  const [showCreateCustomAnalyzerDialog, setShowCreateCustomAnalyzerDialog] =
    useState<boolean>(false);

  async function copyQuestionToClipboard() {
    await navigator.clipboard.writeText(request_message!);
    showSnackbar("Copied!");
  }

  async function copyResponseToClipboard() {
    await navigator.clipboard.writeText(response!);
    showSnackbar("Copied!");
  }

  async function copyAnalyzerInstructionsToClipboard() {
    const customAnalyzer = analyzer as CustomAnalyzer;
    await navigator.clipboard.writeText(customAnalyzer.question);
    showSnackbar("Copied!");
  }

  async function deleteQuestion() {
    await organization(selectedOrg)
      .document(document.id)
      .request(data.id)
      .delete();
  }

  function handleAddCustomAnalyzer() {
    setShowCreateCustomAnalyzerDialog(true);
  }

  const [analyzer, loadingAnalyzer, , snapshot] = useFsDoc(
    // todo use correct field
    organization(selectedOrg).analyzer(
      request_type === DocumentRequestType.RUN_ANALYZER
        ? data.analyzer_id
        : null
    )
  );

  const menuItems = useMemo<(IconMenuItem | null)[]>(() => {
    const result: (IconMenuItem | null)[] = [];
    if (request_type === DocumentRequestType.QUESTION) {
      result.push({
        name: "Copy Question",
        action: copyQuestionToClipboard,
        icon: <ContentCopyIcon />,
      });
    }

    result.push({
      name: "Copy Response",
      action: copyResponseToClipboard,
      icon: <ContentCopyIcon />,
    });

    if (request_type === DocumentRequestType.QUESTION) {
      result.push(null);
      result.push({
        name: "Make Custom Analyzer",
        action: handleAddCustomAnalyzer,
        icon: <LibraryAddIcon />,
      });
    }

    if (analyzer) {
      result.push(null);
      result.push({
        name: "View Analyzer",
        action: `/analyzers/${analyzer.id}`,
        icon: <LaunchIcon />,
      });
      if (analyzer.type == AnalyzerType.CUSTOM) {
        result.push({
          name: "Edit Analyzer",
          action: () => setShowAnalyzerDialog(true),
          icon: <EditIcon />,
        });
        result.push({
          name: "Copy Analyzer Instructions",
          action: copyAnalyzerInstructionsToClipboard,
          icon: <ContentCopyIcon />,
        });
      }
    }

    result.push(null);
    result.push({
      name: "Delete",
      action: deleteQuestion,
      icon: <DeleteIcon />,
    });
    return result;
  }, [request_type, analyzer, setShowAnalyzerDialog]);

  const animateText = data.status !== StatusEnum.COMPLETE;
  const smoothText = useTextSmooth(response ?? "", 10, 2, animateText);

  const title = useMemo(() => {
    if (request_type === DocumentRequestType.RUN_ANALYZER) {
      if (!analyzer) {
        return "Analyzer";
      }

      if (analyzer.type === AnalyzerType.LEGAL) {
        return `Legal Analyzer - ${analyzer?.name}`;
      } else if (analyzer.type === AnalyzerType.CUSTOM) {
        return `Custom Analyzer - ${analyzer?.name}`;
      }
      return "Unknown Analyzer";
    }

    const result = requestTypeMap[request_type];
    if (!result) {
      console.log(`Unknown request type: '${request_type}'`);
    }
    return result;
  }, [request_type, loadingAnalyzer]);

  const analyzerDisplay = useMemo<ReactNode | undefined>(() => {
    if (request_type !== DocumentRequestType.RUN_ANALYZER) {
      return undefined;
    }

    if (!snapshot?.exists()) {
      return (
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <ErrorOutlineIcon sx={{ fontSize: 18 }} color={"error"} />
          <Typography color={"error"}>
            This analyzer no longer exists
          </Typography>
        </Stack>
      );
    }

    return undefined;
  }, [analyzer, loadingAnalyzer, snapshot]);

  return (
    <>
      <QuestionAndResponseCard
        title={title}
        agent_id={data?.agent_id ?? MaiAgentId.DEFAULT}
        owner={data?.owner}
        created_at={created_at}
        question_html={analyzerDisplay}
        question={request_message}
        loading={loading}
        error={error}
        response_text={response ?? ""}
        response={
          <Typography
            variant="body1"
            component="span"
            sx={{
              "& p": { mt: 0 },
              overflowWrap: "break-word",
            }}
          >
            <ReactMarkdown>{smoothText}</ReactMarkdown>
          </Typography>
        }
        menuItems={menuItems}
      />
      <CreateCustomAnalyzerDialog
        open={showCreateCustomAnalyzerDialog}
        initialQuestionText={request_message}
        onClose={() => setShowCreateCustomAnalyzerDialog(false)}
        callback={() => {
          // no-op
        }}
      />
      <EditCustomAnalyzerDialog
        open={showAnalyzerDialog}
        analyzer={analyzer as CustomAnalyzer}
        close={() => setShowAnalyzerDialog(false)}
      />
    </>
  );
}

export default DocumentQuestionCard;
