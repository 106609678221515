import * as React from "react";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import ChatInputBox from "../components/chat/ChatInputBox";
import useAiChat from "../components/chat/useAiChat";
import { fixHref } from "../components/utils/Utils";
import { MAI_DEFAULT_AGENT } from "../models/Models";

function AiChatBar() {
  const navigate = useNavigate();

  const chatState = useAiChat("", MAI_DEFAULT_AGENT);

  async function sendMessage(message: string) {
    const sessionId = await chatState.sendMessage(message);
    navigate(fixHref(`/chat/${sessionId}`));
  }

  return (
    <Stack
      sx={{ width: "100%" }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box
        sx={{
          p: 2,
          borderTop: 1,
          borderTopColor: "rgba(0, 0, 0, 0.12)",
          backgroundColor: "background.default",
          width: "100%",
        }}
      >
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ pr: 6 }}
        >
          <AutoAwesomeIcon sx={{ fontSize: 18, color: "primary.main" }} />
          <Typography
            color={"primary"}
            component={"div"}
            sx={{
              fontSize: "12pt",
              fontWeight: 800,
              color: "primary.main",
              width: "120px",
            }}
          >
            Mysticetus AI
          </Typography>
          <ChatInputBox
            sx={{ maxWidth: undefined }}
            label={"Chat with Mysticetus AI"}
            sendMessage={sendMessage}
          />
        </Stack>
      </Box>
    </Stack>
  );
}

export default AiChatBar;
