import * as React from "react";

import { Chip } from "@mui/material";

import { AiChatConfig } from "./AiChatType";

function ChatAgentChip({ chatConfig }: Props) {
  return (
    <Chip
      variant="outlined"
      size={"small"}
      icon={chatConfig.icon}
      label={chatConfig.name}
      color={chatConfig.color}
    />
  );
}

interface Props {
  chatConfig: AiChatConfig;
}

export default ChatAgentChip;
