import React from "react";

import { Box, Chip, Stack } from "@mui/material";

import { QueryHubMetadata, ToolCallEvent } from "../../models/Models";

interface FilesUsedDisplayProps {
  toolsUsed: ToolCallEvent[];
}

const FilesUsedDisplay: React.FC<FilesUsedDisplayProps> = ({ toolsUsed }) => {
  return (
    <div>
      <h3>Files Used:</h3>
      <Box sx={{ overflowX: "auto", whiteSpace: "nowrap" }}>
        <Stack direction="column" spacing={1}>
          {toolsUsed.map((toolCall) => {
            const metadata = toolCall.tool_call_metadata;
            return metadata.files_used.map((file, index) => {
              // Declare fileName inside the map function
              const fileName = file.split("/").pop() || file;

              return (
                <Chip
                  key={`${metadata.hub_id}-${file}-${index}`}
                  label={`${metadata.hub_name}: ${fileName}`}
                  variant="outlined"
                />
              );
            });
          })}
        </Stack>
      </Box>
    </div>
  );
};

export default FilesUsedDisplay;
