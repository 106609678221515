import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  UpdateData,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { db } from "../configs/firebase";
import { IFsModel } from "./FirestoreUtils";
import { FsCollectionRef } from "./FsCollectionRef";

export abstract class FsDocRef<T extends IFsModel> {
  public readonly parent: FsCollectionRef<T, FsDocRef<T>>;
  public readonly id: string | null;

  public constructor(
    parent: FsCollectionRef<T, FsDocRef<T>>,
    id: string | null
  ) {
    this.parent = parent;
    this.id = id;
  }

  public getPath(): string {
    return this.parent.getPath() + "/" + this.id;
  }

  public isAnyIdUndefined(): boolean {
    return this.isIdUndefined() || this.parent.isAnyIdUndefined();
  }

  public isIdUndefined(): boolean {
    return !this.id;
  }

  public get docRef(): DocumentReference<T, DocumentData> {
    if (this.isAnyIdUndefined()) {
      // @ts-ignore
      return null;
    }
    const path = this.getPath();
    return doc(db, path).withConverter(this.parent);
  }

  async update<T2 extends T = T>(value: UpdateData<T2>): Promise<void> {
    const data: DocumentData = { ...value };
    await updateDoc(this.docRef, data);
  }

  async set(value: T): Promise<void> {
    await setDoc(this.docRef, value);
  }

  async delete(): Promise<void> {
    await deleteDoc(this.docRef);
  }

  async get(): Promise<DocumentSnapshot<T>> {
    return await getDoc(this.docRef);
  }
}
