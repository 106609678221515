import React, { ReactNode, useEffect, useMemo, useRef } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Box,
  Card,
  CardContent,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { ThreeDots } from "react-loader-spinner";

import { MaiAgentId, UserId } from "../models/Models";
import { GlobalSnackbarContext } from "./GlobalSnackbarContext";
import IconMenu, { IconMenuItem, renderMenuItems } from "./IconMenu";
import RightClickable from "./RightClickable";
import UserAvatar from "./UserAvatar";
import { getChatConfig } from "./chat/AiChatType";
import ChatAgentChip from "./chat/ChatAgentChip";
import ErrorDisplay from "./utils/ErrorDisplay";
import NiceDateAgo from "./utils/NiceDateAgo";

interface Props {
  title: string;
  owner: UserId;
  agent_id: MaiAgentId;
  created_at: Timestamp;
  question: string | undefined;
  question_html?: ReactNode;
  response: string | React.JSX.Element | null;
  response_text: string;
  error: Error | string | null;
  loading: boolean;
  menuItems: (IconMenuItem | null)[];
}

function QuestionAndResponseCard(props: Props): React.JSX.Element {
  const responseRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (props.loading && responseRef.current) {
      responseRef.current.scrollTop = responseRef.current.scrollHeight;
    }
  }, [props.response, props.loading]);

  const agentConfig = useMemo(
    () => getChatConfig(props.agent_id),
    [props.agent_id]
  );

  return (
    <>
      <RightClickable menuItems={props.menuItems}>
        <Card sx={{ mb: 2 }} variant={"outlined"}>
          <CardContent sx={{ position: "relative" }}>
            <Box sx={{ position: "absolute", right: "16px", top: "16px" }}>
              <IconMenu
                menuItems={props.menuItems}
                id={`${props.title}-menu`}
              />
            </Box>
            <Typography
              variant={"h6"}
              gutterBottom
              color={agentConfig.color}
              sx={{
                textTransform: "uppercase",
                textOverflow: "ellipsis",
              }}
              noWrap
            >
              {props.title}
            </Typography>
            {props.question && (
              <Stack
                direction={"row"}
                alignItems={"top"}
                spacing={2}
                sx={{ mt: 2 }}
              >
                <UserAvatar uid={props.owner} />
                <Typography
                  variant="body1"
                  color="text.secondary"
                  component="div"
                >
                  {props.question}
                </Typography>
              </Stack>
            )}
            {props.question_html && (
              <Box sx={{ mt: 1 }}>{props.question_html}</Box>
            )}
            <Typography
              variant={"overline"}
              sx={{
                fontWeight: 800,
                color: "primary.main",
                display: "block",
                mt: 1,
              }}
              color={agentConfig.color}
            >
              Response
            </Typography>
            <Box sx={{ overflow: "auto", overflowX: "auto" }} ref={responseRef}>
              {props.response}
              {props.loading && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ThreeDots
                    height="40"
                    width="50"
                    color="#000"
                    ariaLabel="loading"
                  />
                </Box>
              )}
            </Box>
            <ErrorDisplay error={props.error} />
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{ mt: 2 }}
            >
              <ChatAgentChip chatConfig={agentConfig} />
              <NiceDateAgo
                date={props.created_at}
                sx={{
                  color: "text.secondary",
                  mb: 1.5,
                  display: "inline",
                  fontSize: 14,
                }}
              />
            </Stack>
          </CardContent>
        </Card>
      </RightClickable>
    </>
  );
}

export default QuestionAndResponseCard;
