import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import HistoryIcon from "@mui/icons-material/History";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import ChatAgentChip from "../../components/chat/ChatAgentChip";
import ChatInputBox from "../../components/chat/ChatInputBox";
import ChatMessageDisplay, {
  ChatMessagePart,
} from "../../components/chat/ChatMessageDisplay";
import useAiChat from "../../components/chat/useAiChat";
import AppPage from "../../components/navbar/AppPage";
import ErrorDisplay from "../../components/utils/ErrorDisplay";
import { fixHref } from "../../components/utils/Utils";
import { OrgContext } from "../../contexts/OrgContext";
import {
  AgentConfig,
  AgentType,
  MAI_DEFAULT_AGENT,
  MaiAgentId,
  StatusEnum,
} from "../../models/Models";
import ChatSessionPickerDialog from "./ChatSessionPickerDialog";

export const AGENT_TYPE_PARAM_KEY = "agentType";
export const AGENT_ID_PARAM_KEY = "agentId";

function getAgentConfigFromParams(params: URLSearchParams): null | AgentConfig {
  const agentType = params.get(AGENT_TYPE_PARAM_KEY);
  const agentId = params.get(AGENT_ID_PARAM_KEY);

  if (agentType === AgentType.MAI) {
    return {
      agent_type: AgentType.MAI,
      // TODO: check that agentId is actually one of MaiAgentId if non-null
      // (also, its insane that TS doesn't flag this cast)
      agent_id: (agentId as null | MaiAgentId) ?? MaiAgentId.DEFAULT,
    };
  } else if (agentType === AgentType.CUSTOM && typeof agentId === "string") {
    return {
      agent_type: AgentType.CUSTOM,
      agent_id: agentId,
    };
  }

  return null;
}

function ChatPageV2() {
  const navigate = useNavigate();
  const { sessionId: initSessionId } = useParams();
  const { selectedOrg } = useContext(OrgContext);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const urlAgentConfig = getAgentConfigFromParams(searchParams);

  useEffect(() => {
    // force selection
    if (!urlAgentConfig) {
      console.log("Forcing default chat");
      setSearchParams({
        ...searchParams,
        [AGENT_ID_PARAM_KEY]: MAI_DEFAULT_AGENT.agent_id,
        [AGENT_TYPE_PARAM_KEY]: MAI_DEFAULT_AGENT.agent_type,
      });
    }
  }, [urlAgentConfig]);

  const {
    messages,
    loading,
    error,
    sendMessage,
    sessionId,
    agentConfig,
    chatConfig,
  } = useAiChat(initSessionId, urlAgentConfig ?? MAI_DEFAULT_AGENT);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State to control the previous chat sessions drawer

  function handleOpenDrawer() {
    setIsDrawerOpen(true);
  }

  function handleCloseDrawer() {
    setIsDrawerOpen(false);
  }

  // Fetch session messages and listen for updates
  useEffect(() => {
    if (sessionId && selectedOrg) {
      // Update URL to reflect the current sessionId
      navigate(
        fixHref(
          `/chat/${sessionId}?agentType=${agentConfig.agent_type}&agentId=${agentConfig.agent_id}`
        ),
        {
          replace: true,
        }
      );
    }
  }, [sessionId, selectedOrg]);

  // auto scroll to bottom when messages changes
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const splitMessages: ChatMessagePart[] = useMemo(() => {
    return messages.flatMap((m) => {
      const result: ChatMessagePart[] = [
        {
          id: m.id + "-user",
          text: m.user_message,
          role: "user",
          status: StatusEnum.COMPLETE,
          tools_used: [],
          sent_at: m.created_at,
        },
      ];

      if (m.model_message) {
        result.push({
          id: m.id + "-model",
          text: m.model_message,
          role: "model",
          status: m.status,
          tools_used: m.tools_used,
          sent_at: m.created_at,
        });
      }
      return result;
    });
  }, [messages]);
  return (
    <AppPage pageType={"flat"}>
      {(urlAgentConfig || sessionId) && (
        <Box display="flex" flexDirection="column" height="90vh">
          {!sessionId && (
            <Alert severity="info" sx={{ m: 5 }}>
              {chatConfig?.description}
            </Alert>
          )}
          <Box flexGrow={1} overflow="auto" p={2}>
            <List sx={{ overflow: "auto" }}>
              {splitMessages.map((message) => (
                <ListItem key={message.id}>
                  <ChatMessageDisplay
                    message={message}
                    agentConfig={agentConfig}
                  />
                </ListItem>
              ))}
              <div ref={messagesEndRef} />
            </List>
            {loading && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
                <Typography variant="body2" sx={{ ml: 2 }}>
                  Waking up the AI...
                </Typography>
              </Box>
            )}
            {/* We're loading the session messages */}
            {messages.length === 0 && loading && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
                <Typography variant="body2" sx={{ ml: 2 }}>
                  Loading chat history...
                </Typography>
              </Box>
            )}
            <ErrorDisplay error={error} />
          </Box>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Card sx={{ p: 2, maxWidth: "800px", width: "100%" }}>
              <Stack
                direction={"row"}
                spacing={1}
                sx={{ mb: 2 }}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <Typography
                    variant={"overline"}
                    color={"text.secondary"}
                    noWrap
                  >
                    Agent
                  </Typography>
                  {chatConfig && <ChatAgentChip chatConfig={chatConfig} />}
                  <Tooltip title={chatConfig?.description}>
                    <InfoIcon sx={{ color: "text.disabled", fontSize: 20 }} />
                  </Tooltip>
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <IconButton onClick={handleOpenDrawer}>
                  <HistoryIcon />
                </IconButton>
                <ChatInputBox
                  sendMessage={async (msg) => {
                    await sendMessage(msg);
                  }}
                />
              </Stack>
            </Card>
          </Stack>
        </Box>
      )}
      <ChatSessionPickerDialog
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
      />
    </AppPage>
  );
}

export default ChatPageV2;
