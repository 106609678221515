import React, { useContext, useEffect, useState } from "react";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

import { AiChatConfig, getChatConfig } from "../components/chat/AiChatType";
import { OrgContext } from "../contexts/OrgContext";
import { organization } from "../fs/FirestoreUtils";
import { Agent, AgentConfig, MaiAgentId } from "../models/Models";

export enum State {
  Ok,
  Loading,
  Error,
}

export type Result<T, E extends Error = Error> =
  | { state: State.Ok; value: T }
  | { state: State.Loading; value?: T }
  | { state: State.Error; value?: T; error: E };

function tryGetChatConfig(agent_id: string | MaiAgentId): null | AiChatConfig {
  switch (agent_id) {
    case MaiAgentId.DEFAULT:
    case MaiAgentId.MARKETING:
    case MaiAgentId.PROPOSAL:
      return getChatConfig(agent_id);
    default:
      return null;
  }
}

function makeConfigFromAgent(agent?: Agent): null | AiChatConfig {
  if (!agent) {
    return null;
  }

  const color = agent.color ?? "secondary";

  return {
    name: agent.name,
    icon: <AutoAwesomeIcon sx={{ fontSize: 18, color } as SxProps<Theme>} />,
    color,
    description: agent.description ?? "TODO",
    useCase: agent.useCase,
  };
}

export default function useChatConfig(
  agentConfig: AgentConfig
): Result<AiChatConfig> {
  const { selectedOrg } = useContext(OrgContext);

  const [config, setConfig] = useState<null | AiChatConfig>(
    // try to load a statically known config right away, that way
    // we dont need to do any loading or wait for a rerender
    // via a useEffect + setState combo
    tryGetChatConfig(agentConfig.agent_id)
  );

  const [error, setError] = useState<null | Error>(null);

  // set isLoading = true right away if the config
  // needs to be built from a custom agent.
  const [isLoading, setIsLoading] = useState<boolean>(!config);

  // since we need to conditionally load from firestore,
  // we cant use the useFsDoc hook, and we're stuck
  // retriving from firestore manually in a useEffect.
  useEffect(() => {
    // if we could load a known config, bail
    if (config) {
      return;
    }

    // otherwise load from firestore
    setIsLoading(true);
    organization(selectedOrg)
      .agent(agentConfig.agent_id)
      .get()
      .then((snapshot) => setConfig(makeConfigFromAgent(snapshot.data())))
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [config, agentConfig.agent_type, agentConfig.agent_id]);

  if (isLoading) {
    return { state: State.Loading, value: config ?? undefined };
  } else if (error) {
    return { state: State.Error, error, value: config ?? undefined };
  } else if (config) {
    return { state: State.Ok, value: config };
  } else {
    console.error({ error, isLoading, config, selectedOrg });
    throw new Error("invalid state");
  }
}
